import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import Link from 'react-scroll/modules/components/Link';
import NameTitle from './NameTitle';


function HeaderBase(props) {

    const [information, setInformation] = useState("");
    const [navigationToggler, setNavigationToggler] = useState(false);

    const handleNavigationToggler = () => {
        console.log("Close/Open toggler")
        setNavigationToggler(!navigationToggler);
    }

    useEffect(() => {
        axios.get('/api/information')
            .then(response => {
                setInformation(response.data);
            })
    }, [])

    //We have this to pass the setter in order to close the toggler when a menu link is clicked in small devices
    const children = React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, {
          handleNavToggler: handleNavigationToggler
        });
      });

    return (
        <nav className={navigationToggler ? "mi-header is-visible" : "mi-header"}>
            <button onClick={handleNavigationToggler} className="mi-header-toggler">
                {!navigationToggler ? <LineIcon name="menu" /> : <LineIcon name="close" />}
            </button>
            <div className="mi-header-inner">
                <div className="mi-header-image">
                    <a href="/">
                        <img src={information.brandImage} alt="brandimage" />
                    </a>                    
                    <NameTitle/>        
                </div>

                <ul className="mi-header-menu">
                    {children}
                </ul>
                <p className="mi-header-copyright">&copy; {new Date().getFullYear()} <b><a rel="noopener noreferrer" target="_blank" href="https://danielmoka.com">DanielMoka</a></b></p>
            </div>
        </nav>
    )
}


export default HeaderBase;