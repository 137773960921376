import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import { Link, NavLink } from 'react-router-dom';
import HeaderBase from './HeaderBase';

//  react scroll - https://hackernoon.com/how-to-make-a-silky-smooth-scroll-in-react-5dj3xdl
function HeaderWithLinkBackToHome() {
    const [information, setInformation] = useState("");
    const [navigationToggler, setNavigationToggler] = useState(false);

    const handleNavigationToggler = () => {
        setNavigationToggler(!navigationToggler);
    }

    useEffect(() => {
        axios.get('/api/information')
            .then(response => {
                setInformation(response.data);
            })
    }, [])

    return (
        <HeaderBase>
            <li><NavLink exact to="/"><span>Back to portfolio</span></NavLink></li>
            {/*<li><NavLink exact to="/blog-best-practices"><span>Best practices</span></NavLink></li>
            <li><NavLink exact to="/blog-web3"><span>Web3</span></NavLink></li>*/}
        </HeaderBase>
    )
}


export default HeaderWithLinkBackToHome;