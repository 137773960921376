import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet/lib/Helmet";
import PageLayout from "../components/PageLayout";
import LayoutWithLinkBackToBlogs from "../components/LayoutWithLinkBackToBlogs";

import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import {atomDark as CodeStyle} from 'react-syntax-highlighter/dist/esm/styles/prism';
import readPostMeta from '../data/db/database'

//good themes: atomDark,cb,dracula,vscDarkPlus,a11yDark
const CodeBlock = ({className, children}) => {
  let lang = 'text'; // default monospaced text
  if (className && className.startsWith('lang-')) {
    lang = className.replace('lang-', '');
  }
  return (
    <SyntaxHighlighter language={lang} style={CodeStyle}>
      {children}
    </SyntaxHighlighter>
  );
}

// markdown-to-jsx uses <pre><code/></pre> for code blocks.
const PreBlock = ({children, ...rest}) => {
  if ('type' in children && children ['type'] === 'code') {
    return CodeBlock(children['props']);
  }
  return <pre {...rest}>{children}</pre>;
};

function BlogDetails(props) {
  const [metadata, setMetadata] = useState("");
  const [content, setContent] = useState("");
  const blogId = props.match.params.id;
  const blogFile = props.match.params.title;
  const fileName = `${blogFile}.md`;

  //Needed because on mobile it does not jump to the top of the page when a post is loaded
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    import(`../blog/${fileName}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setContent(res))
                    .then(res => setMetadata(readPostMeta(blogFile)))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
  });

  return (
    <LayoutWithLinkBackToBlogs route={props.route} id="blog-details">
      <Helmet>
        <title>{metadata.title}</title>
        <meta
          name="description"
          content={metadata.description}
        />
      </Helmet>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
            <Markdown options={{
                overrides: {
                  pre: PreBlock,
                },
              }}>
                {content}
            </Markdown>
        </div>
      </div>
    </LayoutWithLinkBackToBlogs>
  );
}

export default BlogDetails;
