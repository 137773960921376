import Mock from "../mock";

const database = {
  information: {
    name: 'Daniel Moka',
    aboutContent: "I am a software engineer and my main goal is to make the customer happy by producing quality software with the best software engineering practices.",    
    aboutContentDetailed: "Building software is both my passion and my profession. My main goal is to craft clean solutions with high quality, elegance, and attention to details. I have a solid background using agile methodologies such as Extreme Programming and I am driven by providing value with the state-of-the-art technologies. I am a big fan of Test-Driven Development and Domain Driven Design and I am interested in everything that can help to create well designed software. I have demonstrated to have a strong ability to educate and mentor others in the field to fulfill their potential and to deepen my knowledge. Beyond software engineering, I love doing sports (running, wake-skating, body-building, squash) and I am keen on learning languages (English, Dutch, German, Chinese, French).",
    title: "Daniel Moka • Software Craftsman",
    metaDescription: "I am Daniel Moka software craftsman and my main goal is to make the customer happy by producing quality software with the best software engineering practices.",
    age: 0,
    phone: '',
    nationality: 'Hungarian',
    language: 'English, Dutch, Hungarian',
    email: 'danielmoka@protonmail.com',
    address: '',
    freelanceStatus: 'Available',
    socialLinks: {
      /*facebook: 'https://facebook.com',*/
      twitter: 'https://twitter.com/dmokafa',
      linkedin: 'https://www.linkedin.com/in/danielmoka/',
      github: 'https://github.com/dmoka'
    },
    brandImage: '/images/brand-logo.png',
    aboutImage: '/images/brand-large.png',
    aboutImageLg: '/images/brand-large.png',
    cvfile: '/files/DanielMoka _SoftwareCraftsman_CV.pdf'
  },
  services: [
    {
      title: "Best Engineering Practices",
      icon: 'code',
      details: "Striving for producing quality software by using best software engineering practices such as Extreme Programming, Test-Driven Development and Domain-Driven Design."
    },
    {
      title: "Cloud Solution Architect",
      icon: 'cloud',
      details: "More than 5 years experience in designing, implementing and monitoring cloud native applications using Azure with technologies like serverless computing and Kubernetes."
    },
    {
      title: "Mentoring and Advocating",
      icon: 'grow',
      details: "Keen on mentoring others, advocating and sharing my knowledge to raise the software quality standards worldwide in the software engineering community."
    }
  ],
  reviews: [
    {
      id: 1,
      content: "Cannot recommend this fantastic person enough. Passionate about Test Driven Development always applying the best practices about software craftsmanship.",
      author: {
        name: 'Paulo Caetano',
        designation: 'Senior Software Designer at Philips Healthcare'
      }
    },
    {
      id: 2,
      content: "Daniel is a talented software engineer who combines his thorough technical skill set with great enthusiasm and perseverance.",
      author: {
        name: 'Patrick Bronneberg',
        designation: 'Technical Department Manager at Philips IGT Systems'
      }
    }
  ],
  stacks: [
    {
      title: "Practices",
      details: "Extreme Programming, Test-Driven Development, Domain-Driven Design and DevOps practices"
    },
    {
      title: ".NET stack",
      details: "C#, PowerShell, .NET Core, EF Core and REST APIs"
    },
    {
      title: "Azure",
      details: "Azure DevOps, App Services, Kubernetes, Function Apps, API Management, Static Web Apps, Logic Apps, Azure SQL Servers, Storage Accounts, etc."
    },
    {
      title: "Web3",
      details: "Rust, Substrate"
    },
    {
      title: "Frontend",
      details: "Javascript, TypeScript and React"
    },
    {
      title: "Data stack",
      details: "PostgreSQL, MSSQL and Oracle databases"
    }
  ],
  skills: [
    {
      title: "HTML5",
      value: 95
    },
    {
      title: "CSS3",
      value: 90
    },
    {
      title: "Javascript",
      value: 70
    },
    {
      title: "jQuery",
      value: 85
    },
    {
      title: "ReactJS",
      value: 80
    },
    {
      title: "Photoshop",
      value: 65
    }
  ],
  projects: [
    {
      id: 1,
      title: "Craft Better Software Newsletter",
      subtitle: "Newsletter about designing quality software",
      imageUrl: "/images/project-newsletter-small.png",
      largeImageUrl: ["/images/project-newsletter-small.png"],
      url: 'https://craftbettersoftware.com'
    },
    {
      id: 2,
      title: "TDD Manifesto",
      subtitle: "Test-Driven Development in every home",
      imageUrl: "/images/tdd-manifesto-small.png",
      largeImageUrl: ["/images/tdd-manifesto.jpg"],
      url: 'https://tddmanifesto.com'
    },
    {
      id: 3,
      title: "Fluent-asserter",
      subtitle: "Fluent assertions library for Rust",
      imageUrl: "/images/fluent-asserter-logo-white-bg.png",
      largeImageUrl: ["/images/fluent-asserter-logo-white-bg.png"],
      url: 'https://github.com/mirind4/fluent-asserter'
    },
    {
      id: 4,
      title: "Test-Driven Development Conference",
      subtitle: "Organizing the first international TDD conference",
      imageUrl: "/images/tdd-conf-small.webp",
      largeImageUrl: ["/images/tdd-conf-small.webp"],
      url: 'https://www.youtube.com/playlist?list=PLJ3Q-TNrdsXi-och0A0PaXKojDlxv4YsB'
    }
  ],
  portfolios: [
    {
      id: 1,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 2,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 3,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 4,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 5,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 6,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 7,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 8,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 9,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 10,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 11,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 12,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 13,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 14,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    },
    {
      id: 15,
      title: "Pendrive",
      subtitle: "Free pendrive mockup design.",
      imageUrl: "/images/portfolio-image-6.jpg",
      largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    },
    {
      id: 16,
      title: "Beautiful Pendrive",
      subtitle: "Pendrive with great design & flexible.",
      imageUrl: "/images/portfolio-image-7.jpg",
      largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
      url: 'https://twitter.com'
    },
    {
      id: 17,
      title: "Sticker",
      subtitle: "Clip sticker mockup design.",
      imageUrl: "/images/portfolio-image-8.jpg",
      largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    },
    {
      id: 18,
      title: "Packet",
      subtitle: "Beautiful packet & product design.",
      imageUrl: "/images/portfolio-image-9.jpg",
      largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    },
    {
      id: 19,
      title: "T-shirt Mockup",
      subtitle: "A beautiful t-shirt mockup.",
      imageUrl: "/images/portfolio-image-1.jpg",
      largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
      url: 'https://dribbble.com'
    },
    {
      id: 20,
      title: "Coffee Mug",
      subtitle: "Awesome coffee mug design.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "/images/portfolio-image-4-lg2.jpg"
      ],
      url: 'https://facebook.com'
    },
    {
      id: 21,
      title: "Tea & Coffee Mug",
      subtitle: "Beautiful mug with logo.",
      imageUrl: "/images/portfolio-image-2.jpg",
      url: 'https://pinterest.com'
    },
    {
      id: 22,
      title: "Pen Holder",
      subtitle: "A pen holder with beautiful design.",
      imageUrl: "/images/portfolio-image-3.jpg",
      largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
      url: '#'
    },
    {
      id: 23,
      title: "Mug",
      subtitle: "Mug with awesome style",
      imageUrl: "/images/portfolio-image-5.jpg",
      largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "Jun 2022 - Present",
        position: "Software Craftsman",
        company: "Moka IT Engineering (freelance)",
        details: [
          "Helping companies and individuals to apply the best software engineering practices",
          "Transforming teams and leading activities about designing quality software",
          "Building full-stack solutions using cloud-native technologies"]
      },
      {
        id: 2,
        year: "May 2020 - Jun 2022",
        position: "Cloud Solution Engineer",
        company: "OMV, Austria (Vienna)",
        details: [
          "Designing, implementing and operating cloud native, serverless and event-driven software solutions",
          "Development of a Hybrid Integration Platform using the Azure stack, enabling self-service integration solutions",
          "Setting up and supporting API management and DevOps processes",
          "Introducing and implementing best engineering practices such as Extreme Programming, agile testing strategies and Kanban"]
      },
      {
        id: 3,
        year: "Sep 2018 - Oct 2019",
        position: "Software Engineer",
        company: "PHILIPS, Netherlands (Eindhoven)",
        details: [
          "Backend development of an end user healthcare portal for Dutch citizen",
          "Building and deploying services using the .NET stack, Kubernetes (Azure) and micro-service architecture with REST API-s",
          "Leading activities to transform and on-board other teams to apply best practices such as Test Driven Development"]
      },
      {
        id: 4,
        year: "Mar 2016 - Sep 2018",
        position: "Full-stack Software Developer",
        company: "SPS bv, Netherlands (Den Bosch)",
        details: [
          "Full-stack development of a production and warehouse management system which fulfills a scanner-based way of working",
          "Turning business requirements into functional and technical specifications",
          "Building software components using .NET, Java and JavaScript",
          "Integrating third-party solutions to the warehouse management system"
        ]
      },
      {
        id: 5,
        year: "Feb 2015 - Oct. 2015",
        position: "Software Developer",
        company: "Department of Geodesy and Surveying, Hungary (Budapest)",
        details: [
          "Building automated measurement systems based on measurements of robotic total stations",
          "Setting up and executing real-time monitoring works and measurements on famous bridges in Budapest",
          "Defining interfaces for the measurement system using Python"
        ]
      },
      {
        id: 6,
        year: "Set 2012 - Jan. 2015",
        position: "Software Developer",
        company: "Leszing Ltd., Hungary (Budapest)",
        details: [
          "Building tools for facilitating engineering geodesy, cadastral and property valuation works",
          "Executing and processing results of engineering geodesy works based on high precision GPS and geodetic total stations",
        ]
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2013 - 2015",
        graduation: "MSc. Geo-Informatic Engineer",
        university: "Budapest University of Technology and Economics",
        details: [
          "Master thesis titled “Indoor Navigation”",
          "Development of indoor geo-localization system based on augmented reality using high resolution cameras",
          "Building a pedestrian geo-localization tool based on mobile sensors."]
      },
      {
        id: 2,
        year: "2014 - 2015",
        graduation: "Scholarship in Navigation and Environment-Robotics program",
        university: "Leibniz Universität Hannover",
        details: ["ERASMUS semester at the Geographic Information Science and Cartography Department"]
      },
      {
        id: 3,
        year: "2008 - 2013",
        graduation: "BSc. Geoinformatics and Geodesy Engineer",
        university: "Budapest University of Technology and Economics",
        details: ["Bachelor thesis titled “Developing an Automatic Deformation Monitoring System based on robotic total stations”"]
      }
    ]
  },
  posts_best_practices: [
    {
      id: 1,
      title: 'Why Rust rocks',
      featuredImage: '/images/blog-rust-featured-image.webp',
      fileName: "why-rust-rocks",
      filesource: '../../blog/why-rust-rocks.md',
      url: 'https://craftbettersoftware.com/p/the-most-elegant-programming-language',
      createDay: "5",
      createMonth: 'March',
      createYear: "2022",
      meta : {
        title: "Why Rust rocks • Daniel Moka Blog",
        description: "My take about why rust rocks and why it is one of the most elegant, well-designed and most powerful programming language out there.",
     }
    },
    {
      id: 2,
      title: 'Why TDD is a non-negotiable',
      featuredImage: '/images/blog-tdd-featured-image.webp',
      fileName: "test-driven-development",
      filesource: '../../blog/test-driven-development.md',
      url: 'https://craftbettersoftware.com/p/why-tdd-is-a-non-negotiable',
      createDay: "11",
      createMonth: 'February',
      createYear: "2022",
      meta : {
        title: "The power of Test-Driven Development • Daniel Moka Blog",
        description: "This article describes the power and the benefits of using Test-Driven Development (TDD), and why writing tests first is essential to design clean code.",
     }
    },
    {
      id: 3,
      title: 'No trade-off between quality and speed',
      featuredImage: '/images/blog-no-tradeoff.png',
      fileName: "extreme-programming",
      filesource: '../../blog/extreme-programming.md',
      url: 'https://craftbettersoftware.com/p/no-trade-off',
      createDay: "20",
      createMonth: 'December',
      createYear: "2021",
      meta : {
        title: "Extreme Programming • Daniel Moka Blog",
        description: "Extreme Programming and following its practices are literally the best way to be agile and to design clean and testable code in short cycles for the customers.",
     }
    },
    {
      id: 4,
      title: 'The most common developer disease',
      featuredImage: '/images/blog-developer-disease.png',
      fileName: "10-years-10-honest-takes-on-the-industry",
      filesource: '../../blog/10-years-10-honest-takes-on-the-industry.md',
      url: 'https://craftbettersoftware.com/p/the-most-common-developer-disease',
      createDay: "3",
      createMonth: 'November',
      createYear: "2021",
      meta : {
        title: "10 years 10 honest takes on the industry • Daniel Moka Blog",
        description: "My 10 honest takes on the software industry after 10 years of writing software to provide solutions for different kind of problem domains. ",
     }
    },
    {
      id: 5,
      title: 'Why most software teams are inefficient',
      featuredImage: '/images/blog-teams-inefficient.png',
      fileName: "git-best-practices",
      filesource: '../../blog/git-best-practices.md',
      url: 'https://craftbettersoftware.com/p/why-most-software-teams-are-inefficient',
      createDay: "3",
      createMonth: 'August',
      createYear: "2021",
      meta : {
        title: "Git best practices • Daniel Moka Blog",
        description: "An article about describing the best Git practices to get the most out of this most famous source control management technology.",
     }
    },
    {
      id: 6,
      title: 'The TDD Debate: Testing, Design or Development tool?',
      featuredImage: '/images/blog-tdd-debate.png',
      fileName: "why-mob-and-pp-rocks",
      filesource: '../../blog/why-mob-and-pp-rocks.md',
      url: 'https://craftbettersoftware.com/p/the-tdd-debate',
      createDay: "3",
      createMonth: 'April',
      createYear: "2021",
      meta : {
        title: "Why Pair and Mob programming is the way to go • Daniel Moka",
        description: "Why Pair and Mob programming are the ways to go for writing and producing software solutions in the best and most agile way.",
     }
    }
  ],
  posts_web3: [
    {
      id: 1,
      title: 'Web3 Solana smart contract',
      featuredImage: '/images/blog-image-1.jpg',
      filesource: '../../blog/markdown-html-supported-blog.md',
      createDay: "20",
      createMonth: 'February',
      createYear: "2020",
    },
    {
      id: 2,
      title: 'Other web3 content',
      featuredImage: '/images/blog-image-2.jpg',
      filesource: '../../blog/installing-nodejs-on-your-device.md',
      createDay: "18",
      createMonth: 'February',
      createYear: "2020"
    }
  ],
  contactInfo: {
    emailAddress: ['danielmoka@protonmail.com'],
    //address: [""]
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/stacks").reply(config => {
  const response = database.stacks;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/projects").reply(config => {
  const response = database.projects;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/posts-best-practices").reply(config => {
  const response = database.posts_best_practices;
  return [200, response];
});

Mock.onGet("/api/posts-web3").reply(config => {
  const response = database.posts_web3;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});

const readPostMeta = (postName) => {
  var post = database.posts_best_practices.find(p =>  p.fileName.toLowerCase() == postName.toLowerCase()).meta;

  return post;
};

export default readPostMeta;
