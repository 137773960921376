import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import NavLink from 'react-router-dom/NavLink';
import HeaderBase from './HeaderBase';

function HeaderWithLinkBackToBlogs(props) {
    const [information, setInformation] = useState("");
    const [navigationToggler, setNavigationToggler] = useState(false);

    const handleNavigationToggler = () => {
        setNavigationToggler(!navigationToggler);
    }

    useEffect(() => {
        axios.get('/api/information')
            .then(response => {
                setInformation(response.data);
            })
    }, [])

    return (
        <HeaderBase>
            <li><NavLink exact to={`/${props.route}`}><span>Back to posts</span></NavLink></li>
        </HeaderBase>
    )
}


export default HeaderWithLinkBackToBlogs;