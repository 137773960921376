import React from "react";

function StackBar(props){
  return (
    <div className="skill-title">
      <h5 className="skill-title">{props.title}</h5>
      <h6>{props.details}</h6>
    </div>
  );
}

export default StackBar;
