import React from "react";
import BackgroundLines from "./BackgroundLines";
import HeaderWithLinkBackToBlogs from "./HeaderWithLinkBackToBlogs";

function LayoutWithLinkBackToBlogs(props) {
  return (
    <div className="mi-wrapper" id={props.id}>
      <HeaderWithLinkBackToBlogs route = {props.route}/>
      <BackgroundLines />
      {props.children}
    </div>
  );
}

export default LayoutWithLinkBackToBlogs;
