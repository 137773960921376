import axios from "axios";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet/lib/Helmet";
import PageLayout from "../components/PageLayout";
import Pagination from "../components/Pagination";
import ProjectsView from "../components/ProjectsView";
import Sectiontitle from "../components/Sectiontitle";

function Projects() {
  const [information, setInformation] = useState("");
  const [projects, setProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsPerPage] = useState(9);

  useEffect(() => {
    let mounted = true;
    axios.get("/api/projects").then((response) => {
      if (mounted) {
        setProjects(response.data);
      }
    });
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
    return () => (mounted = false);
  }, []);

  const indexOfLastProjects = currentPage * projectsPerPage;
  const indexOfFirstProjects = indexOfLastProjects - projectsPerPage;
  const currentProjects = projects.slice(
    indexOfFirstProjects,
    indexOfLastProjects
  );

  const paginate = (e, pageNumber) => {
    e.preventDefault();
    setCurrentPage(pageNumber);
  };

  return (
    <PageLayout id="projects">
      <Helmet>
          <title>{information.title}</title>
          <meta
            name="description"
            content={information.metaDescription}
          />
      </Helmet>
      <div className="mi-about mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
          <Sectiontitle title="Projects" />
          {<ProjectsView portfolios={currentProjects} />}
        </div>
      </div>
    </PageLayout>
  );
}

export default Projects;
