import React from "react";
import BackgroundLines from "./BackgroundLines";
import HeaderWithLinkBackToHome from "./HeaderWithLinkBackToHome";

function LayoutWithLinkBackToHome(props) {
  return (
    <div className="mi-wrapper" id={props.id}>
      <HeaderWithLinkBackToHome/>
      <BackgroundLines />
      {props.children}
    </div>
  );
}

export default LayoutWithLinkBackToHome;
