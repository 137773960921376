import axios from "axios";
import React, { useEffect, useState } from "react";
import Helmet from "react-helmet/lib/Helmet";
import TrackVisibility from "react-on-screen";
import StackBar from "../components/StackBar";
import Resume from "../components/Resume";
import PageLayout from "../components/PageLayout";
import Sectiontitle from "../components/Sectiontitle";
import Smalltitle from "../components/Smalltitle";

function Resumes() {
  const [information, setInformation] = useState("");
  const [stacks, setStacks] = useState([]);
  const [workingExperience, setWorkingExperience] = useState([]);
  const [educationExperience, setEducationExperience] = useState([]);

  useEffect(() => {
    axios.get("/api/stacks").then((response) => {
      setStacks(response.data);
    });
    axios.get("/api/experience").then((response) => {
      setWorkingExperience(response.data.workingExperience);
      setEducationExperience(response.data.educationExperience);
    });
    axios.get("/api/information").then((response) => {
      setInformation(response.data);
    });
  }, []);

  return (
    <PageLayout id="resumes">
        <Helmet>
          <title>{information.title}</title>
          <meta
            name="description"
            content={information.metaDescription}
          />
        </Helmet>
        <div className="mi-skills-area mi-section mi-padding-top">
          <div className="container">
            <Sectiontitle title="Engineering practices and stacks" />
            <div className="mi-skills">
              <div className="row mt-30-reverse">
                {stacks.map((stack) => (
                  <TrackVisibility
                    once
                    className="col-lg-6 mt-30"
                    key={stack.title}
                  >
                    <StackBar title={stack.title} details={stack.details} />
                  </TrackVisibility>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mi-resume-area mi-section mi-padding-top mi-padding-bottom">
          <div className="container">
            <Sectiontitle title="Resume" />
            <Smalltitle title="Working Experience" icon="briefcase" />
            <div className="mi-resume-wrapper">
              {workingExperience.map((workingExp) => (
                <Resume key={workingExp.id} resumeData={workingExp} />
              ))}
            </div>
            <div className="mt-30"></div>
            <Smalltitle title="Educational Qualifications" icon="book" />
            <div className="mi-resume-wrapper">
              {educationExperience.map((educatonExp) => (
                <Resume key={educatonExp.id} resumeData={educatonExp} />
              ))}
            </div>
          </div>
        </div>
    </PageLayout>
  );
}

export default Resumes;
