import React, { useEffect, useState } from 'react';
import Link from 'react-scroll/modules/components/Link';
import HeaderBase from './HeaderBase';

function HeaderChild(props) {
    const pages = [
        {ref: "home", name: "HOME"},
        {ref: "about", name: "ABOUT"},
        {ref: "resumes", name: "RESUMES"},
        {ref: "projects", name: "PROJECTS"},
        {ref: "blogs", name: "BLOG"},
        {ref: "contact", name: "CONTACT"}
    ];

    const pagesNav = pages.map((page, index) => {
        return (
            <li>
                <Link activeClass="active" to={page.ref} spy={true} onClick={props.handleNavToggler}>
                    {page.name}
                </Link>
            </li>
        );
      });

    return (
        <div>
            {pagesNav}
            {/*<li><NavLink to="/blog-best-practices"><span>Blogs</span></NavLink></li>*/}
        </div>
    )
}


//  react scroll - https://hackernoon.com/how-to-make-a-silky-smooth-scroll-in-react-5dj3xdl
function Header() {
    return (
        <HeaderBase>
            <HeaderChild/>     
        </HeaderBase>
    )
}


export default Header;