import React, { useState } from 'react';
import * as Icon from 'react-feather';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import Header from './components/Header';
import About from './pages/About';
import PostsBestPractices from './pages/PostsBestPractices';
import PostsWeb3 from './pages/PostsWeb3';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Portfolios from './pages/Projects';
import Resumes from './pages/Resumes';
import BlogDetails from './pages/BlogDetails';

const Notfound = React.lazy(() => import('./pages/Notfound'));

function Main(props) {
  return (
    <div>
      <Header />
      <Home />
      <About />
      <Resumes />
      <Portfolios />
      <PostsBestPractices />
      <Contact />
    </div>
  );
}

function App() {
  const [lightMode, setLightMode] = useState(false); // Made it true if you want to load your site light mode primary

  lightMode ? document.body.classList.add('light') : document.body.classList.remove('light');

  const handleMode = () => {
    if (!lightMode) {
      setLightMode(true);
      document.body.classList.add('light');
    } else {
      setLightMode(false);
      document.body.classList.remove('light');
    }
  };

  return (
    <BrowserRouter>
      <div>
        <div className="light-mode">
          <span className="icon">
            <Icon.Sun />
          </span>
          <button
            className={lightMode ? 'light-mode-switch active' : 'light-mode-switch'}
            onClick={() => handleMode()}
          ></button>
        </div>
        <Switch>
          <Route path="/" exact>
            <Main />
          </Route>
          <Route path="/blog-best-practices" exact component={PostsBestPractices} />
          <Route path="/blog-best-practices/:title" render={(props) => <BlogDetails {...props} route={`blog-best-practices`} />} />

          {/*<Route path="/blog-web3" exact component={PostsWeb3} />
          <Route path="/blog-web3/blog-details/:id/:title" render={(props) => <BlogDetails {...props} route={`blog-web3`} />} />
        */}
          <Route path="*" component={Notfound} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
