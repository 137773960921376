import axios from 'axios';
import React, { useEffect, useState } from 'react';
import LineIcon from 'react-lineicons';
import Link from 'react-scroll/modules/components/Link';
import NavLink from 'react-router-dom/NavLink';
import HeaderBase from './HeaderBase';

function NameTitle() {
    return (
        <div className='name'>
            <div>
                Daniel Moka
            </div>
            <div className='name-title'>
                Software Craftsman
            </div>      
        </div>
    )
}

export default NameTitle;